<script>
	import Layout from "../../layouts/main";
	import PageHeader from "@/components/page-header";
	import appConfig from "@/app.config";
	import Vue from 'vue';
	import axios from 'axios';
	import VueAxios from 'vue-axios';

	Vue.use(VueAxios, axios);
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

	export default {
		middleware: "authentication",
		components: {
			Layout,
			PageHeader,
		},
		page: {
			title: "Edit Employee details",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		mounted: function() {},
		data: function() {
			return {
				title: 'Employee',
				items: [
					{
						text: "View",
					},
					{
						text: "Employee List",
						active: true,
					},
				],
				employeeName: '',
				phoneNumber: '',
				employeeDob: '',
				emailId: '',
				address: '',
				dateOfJoining: '',
				designation: '',
				role: '',
				overtimeApplicable: '',
				isManager: '',
				isManagerOptions: [
					'Yes',
					'No',
				],
				reportingManager: '',
				shift: '',
				salary: '',
				pfDeduction: '',
				pfContribution: '',
				esicDeduction: '',
				esicContribution: '',
				otherStatutoryDeductions: '',
			};
		},
		methods: {
			updateEmployeeDetails: function() {},
		},
	}
</script>
<style>
	textarea {
		resize: none;
	}
	.custom-control {
		position: relative;
		z-index: 1;
		display: block;
		min-height: 1rem;
		padding-left: 1rem;
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}
	.custom-control-inline {
		display: -ms-inline-flexbox;
		display: inline-flex;
		margin-right: 1rem;
	}
	.custom-control-inline:last-child {
		margin-right: 0;
	}
	.custom-control-input {
		position: absolute;
		left: 0;
		z-index: -1;
		width: 1rem !important;
		height: 1.25rem !important;
		opacity: 0;
	}
	.custom-control-label {
		position: relative;
		margin-bottom: 0 !important;
		vertical-align: top;
		padding-left: .5rem;
	}
	.custom-control-label:before {
		pointer-events: none;
		background-color: #fff;
		border: 1px solid #adb5bd;
		transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}
	.custom-radio .custom-control-label:before {
		border-radius: 50%;
	}
	.custom-control-label:after, .custom-control-label:before {
		position: absolute;
		top: .25rem;
		left: -1rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: '';
	}
	.custom-control-label:after {
		background: 50%/50% 50% no-repeat;
	}
	.custom-control-input:checked~.custom-control-label:before {
		color: #fff;
		border-color: #EB455F;
		background-color: #EB455F;
	}
	.custom-radio .custom-control-input:checked~.custom-control-label:after {
		background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'> <circle r='3' fill='%23fff'/> </svg>");
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<b-form @submit.prevent="updateEmployeeDetails">
					<div class="card mb-4">
						<div class="card-body">
							<h4 class="card-title">Edit Employee details</h4>
						</div>
					</div>
					<div class="card mb-4">
						<div class="card-body">
							<h4 class="card-title">Personal Details</h4>
							<div class="p-2 mt-4">
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Employee Name" for="employeeName">
											<b-form-input id="employeeName" v-model="employeeName" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Phone Number" for="phoneNumber">
											<b-form-input id="phoneNumber" v-model="phoneNumber" />
										</b-form-group>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Date of Birth" for="employeeDob">
											<b-form-input id="employeeDob" v-model="employeeDob" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Email ID" for="emailId">
											<b-form-input id="emailId" v-model="emailId" />
										</b-form-group>
									</div>
								</div>
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Address" for="address">
											<b-form-textarea id="address" v-model="address" />
										</b-form-group>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-4">
						<div class="card-body">
							<h4 class="card-title">Official Details</h4>
							<div class="p-2 mt-4">
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Date of Joining" for="dateOfJoining">
											<b-form-input id="dateOfJoining" v-model="dateOfJoining" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Designation" for="designation">
											<b-form-input id="designation" v-model="designation" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Role" for="role">
											<b-form-input id="role" v-model="role" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Overtime Applicable" for="overtimeApplicable">
											<b-form-input id="overtimeApplicable" v-model="overtimeApplicable" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Is Manager" for="isManager">
											<b-form-radio-group v-model="isManager" :options="isManagerOptions" name="radio-inline" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Reporing Manager" for="reportingManager">
											<b-form-input id="reportingManager" v-model="reportingManager" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Shift" for="shift">
											<b-form-input id="shift" v-model="shift" />
										</b-form-group>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-4">
						<div class="card-body">
							<h4 class="card-title">Payroll Details</h4>
							<div class="p-2 mt-4">
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Salary" for="salary">
											<b-form-input id="salary" v-model="salary" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="PF Deduction" for="pfDeduction">
											<b-form-input id="pfDeduction" v-model="pfDeduction" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="PF Contribution" for="pfContribution">
											<b-form-input id="pfContribution" v-model="pfContribution" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="ESIC Deduction" for="esicDeduction">
											<b-form-input id="esicDeduction" v-model="esicDeduction" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="ESIC Contribution" for="esicContribution">
											<b-form-input id="esicContribution" v-model="esicContribution" />
										</b-form-group>
									</div>
									<div class="col-lg-6 col-md-6 col-sm-12">
										<b-form-group class="mb-3" label="Other Statutory Deductions" for="otherStatutoryDeductions">
											<b-form-input id="otherStatutoryDeductions" v-model="otherStatutoryDeductions" />
										</b-form-group>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-body">
							<b-button class="btn-btn-outline-primary" type="submit" variant="primary">Submit</b-button>
						</div>
					</div>
				</b-form>
			</div>
		</div>
	</Layout>
</template>